<template>
  <v-app>
    <v-app-bar color="primary" flat height="110">
      <v-spacer></v-spacer>
      <a href="#" class="text-center">
        <img src="/media/logos/logo_light_155x30.png" class="max-h-70px" alt />
      </a>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-content class="mb-12">
      <v-container class="mb-12">
        <v-container class="mb-12">
          <v-row justify="center" align="center">
            <v-col md="8" cols="12">
              <v-card flat>
                <v-card-title>
                  <v-spacer />
                  <div class="text-center">
                    <div class="font-size-h2 bold-text">
                      Download TestApp.io app for Android
                    </div>
                  </div>
                  <v-spacer />
                </v-card-title>
                <v-card-text class="text-center">
                  <div class=" bodyFont mb-4">
                    Download our app to install your releases from phone and
                    give feedback.
                  </div>
                  <div class="mb-4">
                    <v-btn
                      v-if="mobile === 'unknown' || mobile === 'android'"
                      color="primary"
                      :x-large="$vuetify.breakpoint.mdAndUp"
                      :class="$vuetify.breakpoint.mdAndUp ? 'pa-8' : ''"
                      class="text-transform-none bold-text font-size-h3"
                      @click="openDownloadConfirm"
                    >
                      Get our app
                    </v-btn>
                  </div>
                  <v-carousel cycle hide-delimiter-background>
                    <v-carousel-item
                      v-for="(image, i) in images"
                      :key="i"
                      :src="image"
                    >
                    </v-carousel-item>
                  </v-carousel>
                  <div class=" text-left bodyFont my-3">
                    TestApp.io is a platform that helps the developers to get
                    feedback about their apps (APK/IPA) while developing from
                    family, friends, colleagues, testers, clients,... anyone!
                    <br /><br />
                    In our Portal, developers will be able to create releases
                    and invite members to provide their feedback in the chat to
                    work on it and release another one.
                    <br /><br />
                    We aim to increase app development productivity.
                    <br /><br />
                    Made with love by developers for developers.
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-content>
    <v-footer>
      <k-t-footer></k-t-footer>
    </v-footer>
    <Modal v-model="showDownloadConfirm" @close="showDownloadConfirm = false">
      <template #message>
        <v-row class="mt-2">
          <v-col class=" font-size-h3 bold-text" cols="12">
            Are you sure ?
          </v-col>
          <v-col class=" bodyFont">
            The TestApp.io app support only android device. The device you are
            using may not be compatible. <br /><br />
            Do you still want to download ?
          </v-col>
          <v-col class="text-right" cols="12">
            <v-btn
              text
              class="text-transform-none mx-3"
              @click="showDownloadConfirm = false"
              color="primary"
            >
              No
            </v-btn>
            <v-btn @click="downloadMobileApp" depressed color="primary">
              Yes
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
  </v-app>
</template>

<style lang="scss">
.v-btn.install {
  width: 195px !important;
  height: 64px !important;
  border-radius: 10px !important;
  span {
    font-size: 19px !important;
    text-transform: capitalize;
    letter-spacing: 0;
  }
}
img.install-img {
  width: 195px !important;
  height: 64px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTFooter from "@/view/layout/footer/Footer.vue";

export default {
  name: "install",
  components: { KTFooter },
  data() {
    return {
      mobile: "",
      message: "",
      images: [
        "https://testapp.io/images/TestApp.io---App.svg",
        "https://testapp.io/images/TestApp.io---Chat.svg",
        "https://testapp.io/images/TestApp.io---Notifications.svg",
      ],

      secondaryMessage: "",
      showDownloadConfirm: false,
      showAndroidOption: false,
      showIOSOption: false,
      selectedLink: "",
      selectedTitle: "",
      showDownloadModal: false,
      downloadLinks: {
        playStore: {
          link:
            "https://play.google.com/store/apps/details?id=testapp.io&hl=en_US&gl=US",
          title: "Google Play Store",
        },
        huaweiStore: {
          link: "https://appgallery.huawei.com/#/app/C103407753",
          title: "Huawei AppGallery",
        },
        samsungStore: {
          link: "https://galaxy.store/testappio",
          title: "Samsung Galaxy Store",
        },
        amazonStore: {
          link: "https://www.amazon.com/gp/product/B095RFPRNR",
          title: "Amazon AppStore",
        },
        appStore: {
          link: "https://apps.apple.com/us/app/testapp-io/id1518972541",
          title: "Apple App Store",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getActivateErrorMessage: "getActivateErrorMessage",
    }),
  },
  created() {
    if (this.$route.params.message) {
      this.message = this.$route.params.message;
    }
    this.mobile = this.getMobileOperatingSystem;

    if (this.mobile === "unknown") {
      this.secondaryMessage =
        "To create a release go to developer portal and to install a release download our app.";
    } else {
      this.secondaryMessage =
        "Open or install the app form below button to get started.";
    }
  },
  methods: {
    openDownloadConfirm() {
      if (this.mobile !== "android") {
        this.showDownloadConfirm = true;
      }
    },
    downloadMobileApp() {
      window.open(
        "https://github.com/testappio/android/releases/download/1.9/testapp.io_v1.9.apk",
        "_blank"
      );
      this.$router.push({ name: "install-app" });
    },
  },
};
</script>
