var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-app-bar', {
    attrs: {
      "color": "primary",
      "flat": "",
      "height": "110"
    }
  }, [_c('v-spacer'), _c('a', {
    staticClass: "text-center",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "max-h-70px",
    attrs: {
      "src": "/media/logos/logo_light_155x30.png",
      "alt": ""
    }
  })]), _c('v-spacer')], 1), _c('v-content', {
    staticClass: "mb-12"
  }, [_c('v-container', {
    staticClass: "mb-12"
  }, [_c('v-container', {
    staticClass: "mb-12"
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "8",
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_c('v-spacer'), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "font-size-h2 bold-text"
  }, [_vm._v(" Download TestApp.io app for Android ")])]), _c('v-spacer')], 1), _c('v-card-text', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "bodyFont mb-4"
  }, [_vm._v(" Download our app to install your releases from phone and give feedback. ")]), _c('div', {
    staticClass: "mb-4"
  }, [_vm.mobile === 'unknown' || _vm.mobile === 'android' ? _c('v-btn', {
    staticClass: "text-transform-none bold-text font-size-h3",
    class: _vm.$vuetify.breakpoint.mdAndUp ? 'pa-8' : '',
    attrs: {
      "color": "primary",
      "x-large": _vm.$vuetify.breakpoint.mdAndUp
    },
    on: {
      "click": _vm.openDownloadConfirm
    }
  }, [_vm._v(" Get our app ")]) : _vm._e()], 1), _c('v-carousel', {
    attrs: {
      "cycle": "",
      "hide-delimiter-background": ""
    }
  }, _vm._l(_vm.images, function (image, i) {
    return _c('v-carousel-item', {
      key: i,
      attrs: {
        "src": image
      }
    });
  }), 1), _c('div', {
    staticClass: "text-left bodyFont my-3"
  }, [_vm._v(" TestApp.io is a platform that helps the developers to get feedback about their apps (APK/IPA) while developing from family, friends, colleagues, testers, clients,... anyone! "), _c('br'), _c('br'), _vm._v(" In our Portal, developers will be able to create releases and invite members to provide their feedback in the chat to work on it and release another one. "), _c('br'), _c('br'), _vm._v(" We aim to increase app development productivity. "), _c('br'), _c('br'), _vm._v(" Made with love by developers for developers. ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-footer', [_c('k-t-footer')], 1), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.showDownloadConfirm = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "mt-2"
        }, [_c('v-col', {
          staticClass: "font-size-h3 bold-text",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Are you sure ? ")]), _c('v-col', {
          staticClass: "bodyFont"
        }, [_vm._v(" The TestApp.io app support only android device. The device you are using may not be compatible. "), _c('br'), _c('br'), _vm._v(" Do you still want to download ? ")]), _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none mx-3",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.showDownloadConfirm = false;
            }
          }
        }, [_vm._v(" No ")]), _c('v-btn', {
          attrs: {
            "depressed": "",
            "color": "primary"
          },
          on: {
            "click": _vm.downloadMobileApp
          }
        }, [_vm._v(" Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showDownloadConfirm,
      callback: function callback($$v) {
        _vm.showDownloadConfirm = $$v;
      },
      expression: "showDownloadConfirm"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }